import React from "react";
import Routes from "./Routes";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import store from "./redux/store";
import { PersistGate } from "redux-persist/lib/integration/react";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./ErrorFallback";
import { LicenseInfo } from "@mui/x-license-pro"; // License Information
import { CssBaseline, ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./App.css";
import "./fonts/Rubik-VariableFont_wght.ttf";
import { initializeObservability } from "./observability/observability";
import ConfirmationDialogProvider from "./dialogs/contexts/ConfirmationDialogProvider";

const MUI_LICENSE_KEY = process.env.REACT_APP_MUI_LICENSE_KEY;
if (MUI_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
} else {
  console.log(
    "MUI_LICENSE_KEY environment variable is not set. Please set it to the license key provided by MUI.",
  );
}
const logError = (error, info) => {
  console.log("ErrorBoundary caught an error", error, info);
};

Amplify.configure({
  Auth: {
    Cognito: {
      region: awsExports.REGION,
      userPoolId: awsExports.USER_POOL_ID,
      userPoolClientId: awsExports.USER_POOL_APP_CLIENT_ID,
    },
  },
});

initializeObservability(); // Bootstrap obserability abstraction layer on startup

export const persistor = persistStore(store);

const App = () => (
  <Provider store={store}>
    {" "}
    {/* redux */}
    <PersistGate loading={null} persistor={persistor}>
      {" "}
      {/* redux-persist */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={theme}>
          {" "}
          {/* mui */}
          <CssBaseline /> {/* mui */}
          <ErrorBoundary
            fallbackRender={ErrorFallback}
            onError={logError}
            onReset={() => {
              console.log("reset");
            }}
          >
            {" "}
            {/* react-error-boundary */}
            <Authenticator.Provider>
              {" "}
              {/* aws-amplify */}
              <ConfirmationDialogProvider>
                <Routes />
              </ConfirmationDialogProvider>
            </Authenticator.Provider>
          </ErrorBoundary>
        </ThemeProvider>
      </LocalizationProvider>
    </PersistGate>
  </Provider>
);

export default App;
