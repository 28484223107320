
import React, { useState, useEffect } from "react";
import { Stack, Divider, Typography, Tooltip, Box, IconButton } from '@mui/material';
import WarningChip from "../CommonComponents/Chips/WarningChip";
import AddButton from "../CommonComponents/AddButton";
import UpdateContactDialog from "../CommonComponents/Dialogs/UpdateContact";
import { mapDeliveryChannelToLabel, userHasRole } from '../common';
import { PRECEDENT_ADMIN_ROLE, PRECEDENT_EDITOR_ROLE, isPrecedentUser } from "../common-roles";
import KeyValuePair from "./KeyValuePair";
import { matchCarrierByAlias } from "../DemandComponents/DemandForms/FormHelpers";
import { getConfigValue } from '../api';
import { useDispatch, useSelector } from 'react-redux';
import DetailCard from "../DemandComponents/DetailCard";
import { LocalShippingOutlined } from "@mui/icons-material";
import EditDemandButton from "../DemandComponents/DemandForms/EditDemandButton";

const MedicalsOverviewPrecedent = ({ showCarrierPanel, carriersData, saveDeliveryChannel }) => {
    const { user, userData } = useSelector(state => state.User);
    const { documentData, documentMetaData } = useSelector(state => state.Document);

    const dispatch = useDispatch();

    const [dialogState, setDialogState] = useState({
        updateCarrierEmail: false,
        updateCarrierFax: false,
    });

    const [updateDefaultDeliverySwitched, setUpdateDefaultDeliverySwitched] = useState(false);
    const [useDemandNotesEnabled, setUseDemandNotesEnabled] = useState(false);

    const aliases = []
    const carrierNamesList = []

    if (carriersData) {
        carriersData.forEach(carrier => {
            carrierNamesList.push(carrier.name)
            if (carrier.aliases) {
                carrier.aliases.forEach(alias => {
                    aliases.push(alias)
                })
            }
        })
    }

    const currentCarrierName = documentData?.recipientCarrier?.carrierCommonName || ""
    const currentCarrierEmail = documentData?.recipientCarrier?.contactInfo?.emailAddress || ""
    const currentCarrierFax = documentData?.recipientCarrier?.contactInfo?.faxNumber || ""
    const isKnownCarrier = documentData?.recipientCarrier?.carrierCustomerAliasId && documentData?.recipientCarrier?.carrierCustomerAliasId !== ""
    const carrierInfo = matchCarrierByAlias(documentData?.recipientCarrier?.carrierCommonName, carriersData);

    const deliveryChannels = carrierInfo?.deliveryChannels;
    const defaultDeliveryChannelType = carrierInfo?.defaultDeliveryChannelType;

    const emailChannel = deliveryChannels?.find(channel => channel.deliveryChannelType === 'email');
    const emailDestinationAddress = emailChannel?.deliveryDestinationAddress;
    const faxChannel = deliveryChannels?.find(channel => channel.deliveryChannelType === 'fax');
    const faxDestinationAddress = faxChannel?.deliveryDestinationAddress;

    const showEmailAddButton = (userHasRole(PRECEDENT_ADMIN_ROLE, userData) || userHasRole(PRECEDENT_EDITOR_ROLE, userData))
        && carrierInfo && !emailDestinationAddress && currentCarrierEmail;
    const showFaxAddButton = (userHasRole(PRECEDENT_ADMIN_ROLE, userData) || userHasRole(PRECEDENT_EDITOR_ROLE, userData))
        && carrierInfo && !faxDestinationAddress && currentCarrierFax;

    const showFaxNoMatch = carrierInfo && faxDestinationAddress && (currentCarrierFax !== faxDestinationAddress);
    const showEmailNoMatch = carrierInfo && emailDestinationAddress && (currentCarrierEmail !== emailDestinationAddress);

    const handleUpdateContact = () => {
        if (dialogState.updateCarrierEmail) {
            const deliveryChannel = {
                deliveryChannelType: "email",
                deliveryDestinationAddress: currentCarrierEmail
            }
            // saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
            updateDefaultDeliverySwitched && saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
        } else if (dialogState.updateCarrierFax) {
            const deliveryChannel = {
                deliveryChannelType: "fax",
                deliveryDestinationAddress: currentCarrierFax
            }
            // saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
            updateDefaultDeliverySwitched && saveDeliveryChannel(carrierInfo.carrierId, deliveryChannel)
        }
        setDialogState({ updateCarrierEmail: false, updateCarrierFax: false })
    }

    const collaboratorsList = documentData?.sendingFirm.caseManagers || [];
    const collaboratorsValues = collaboratorsList.length > 0 ? collaboratorsList.map(collaborator => collaborator.firstName + " " + collaborator.lastName) : ['Not on File']

    const loadDemandNotesSetting = async () => {
        setUseDemandNotesEnabled((await getConfigValue('useDemandNotesEnabled', null, user)));
    };

    useEffect(() => {
        loadDemandNotesSetting();
    }, []);

    return (
        <>
            <DetailCard
                icon={<LocalShippingOutlined color="action" />}
                label="Delivery"
                action={
                    <EditDemandButton />
                }
            >
                {showCarrierPanel ? (
                    <>


                        <Stack direction="row" spacing={3}>

                            <Stack direction={"row"} spacing={1}>
                                <KeyValuePair label="Carrier email" value={currentCarrierEmail} validation={showEmailNoMatch && <Tooltip title="Carrier email does not match verified delivery email address">
                                    <WarningChip label="No match" />
                                </Tooltip>} callToAction={showEmailAddButton && <AddButton clickHandler={() => setDialogState({ ...dialogState, updateCarrierEmail: true })} />} />
                            </Stack>


                            <Stack direction="row">
                                <KeyValuePair label="Carrier fax" value={currentCarrierFax} validation={showFaxNoMatch && <Tooltip title="Carrier fax does not match verified delivery fax number" >
                                    <WarningChip label="No match" />
                                </Tooltip>} callToAction={showFaxAddButton && <AddButton clickHandler={() => setDialogState({ ...dialogState, updateCarrierFax: true })} />} />
                            </Stack>



                            <KeyValuePair label="Default delivery" value={mapDeliveryChannelToLabel(defaultDeliveryChannelType)} validation={carrierInfo && !documentData?.defaultDeliveryInformationEnabled && <WarningChip label="Disabled" />} />
                        </Stack>





                        {(dialogState.updateCarrierEmail || dialogState.updateCarrierFax) && (
                            <UpdateContactDialog
                                dialogOpen={true}
                                dialogTitle={"Update contact"}
                                dialogText={dialogState.updateCarrierEmail ? currentCarrierEmail : currentCarrierFax}
                                cancelButtonText={"Cancel"}
                                okButtonText={"Update contact"}
                                handleUpdateDefaultDeliverySwitched={setUpdateDefaultDeliverySwitched}
                                updateDefaultDeliverySwitched={updateDefaultDeliverySwitched}
                                handleCancel={() => { setDialogState({ updateCarrierEmail: false, updateCarrierFax: false }) }}
                                handleOk={handleUpdateContact}
                                type={dialogState.updateCarrierEmail ? "email" : "fax"}
                                carrierName={currentCarrierName}
                            />
                        )}
                    </>
                ) : null}

            </DetailCard>

        </>
    )
}

export default MedicalsOverviewPrecedent;

const gridStyles = { px: 1, py: 1 }
const stackSpacing = 1;
const md = 3;
const sm = 6;
const xs = 6;
