import { Grid, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import DetailCard from './DetailCard';
import { AccountBalanceOutlined, AttachMoneyOutlined, GroupOutlined, InfoOutlined } from '@mui/icons-material';
import { formatCurrency, formatDate } from '../common';
import KeyValuePair from '../MedicalsComponents/KeyValuePair';
import WarningChip from '../CommonComponents/Chips/WarningChip';
import { isPrecedentUser } from '../common-roles';
import DemandScoreCard from './DemandScoreCard';

const DetailsAndInsights = ({ providerSummary }) => {
    const { documentData, documentMetaData } = useSelector((state) => state.Document);
    const currentCarrierName = documentData?.recipientCarrier?.carrierCommonName || "";
    const isKnownCarrier = documentData?.recipientCarrier?.carrierCustomerAliasId && documentData?.recipientCarrier?.carrierCustomerAliasId !== "";
    const { user, userData } = useSelector(state => state.User);

    const collaboratorsList = documentData?.sendingFirm.caseManagers || [];
    const collaboratorsValues = collaboratorsList.length > 0 ? collaboratorsList.map(collaborator => collaborator.firstName + " " + collaborator.lastName) : ['Not on File'];

    const gridItemStyles = {
        minWidth: 150,
        flex: "1"
    }


    return (
        <Grid
            container
            sx={{
                width: '100%',
                display: "flex",
                flexWrap: "wrap", // Allows wrapping based on the resizable parent's width
                display: 'flex',
                paddingLeft: 0,
                gap: 2,
                alignItems: 'stretch'
            }}
        >
            {/* details */}
            <Grid
                item
                sx={gridItemStyles}
            >
                <DetailCard
                    icon={<InfoOutlined color="action" />}
                    label="Details"
                >
                    <Stack spacing={1}>
                        <KeyValuePair label="Client" value={documentData?.claimInfo?.claimant?.firstName || "Not on File"} value2={documentData?.claimInfo?.claimant?.lastName ?? ""} />
                        <KeyValuePair label="Insured" value={documentData?.claimInfo?.insuredFirstName || "Not on File"} value2={documentData?.claimInfo?.insuredLastName ?? ""} />
                        <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                        >
                            <KeyValuePair label="Date of loss" value={formatDate(documentData?.claimInfo?.dateOfLoss)} />
                            <KeyValuePair label="Loss state" value={documentData?.claimInfo?.lossState || "Not on File"} />
                        </Stack>
                    </Stack>
                </DetailCard>
            </Grid>

            {/* insurance */}
            <Grid
                item
                sx={gridItemStyles}
            >
                <DetailCard
                    icon={<AccountBalanceOutlined color="action" />}
                    label="Insurance"
                >
                    <Stack
                        spacing={1}
                    >
                        <KeyValuePair label="Carrier" value={currentCarrierName} carrierName={documentData?.recipientCarrier?.carrierCommonName} demandIsDeliverable={documentMetaData.demandIsDeliverable} validation={currentCarrierName && !isKnownCarrier &&
                            isPrecedentUser(userData) && <WarningChip label="No match" />
                        } />
                        <KeyValuePair label="Claim number" value={documentData?.claimInfo?.claimNumber || "Not on File"} />
                        <KeyValuePair label="Claim coverage" value={documentData?.claimInfo?.claimCoverage || "Not on File"} />
                    </Stack>

                </DetailCard>
            </Grid>

            {/* demand */}
            <Grid
                item
                sx={gridItemStyles}
            >
                <DetailCard
                    icon={<AttachMoneyOutlined color="action" />}
                    label="Demand"
                >
                    <Stack
                        spacing={1}
                    >
                        <KeyValuePair label="Medical charges" value={formatCurrency(providerSummary?.[providerSummary.length - 1]?.billedAmount)} />
                        <KeyValuePair label="Demand amount" value={documentData?.demandDetails?.demandOfferToSettleAmount ? formatCurrency(documentData?.demandDetails?.demandOfferToSettleAmount) : 'Policy Limits'} />
                        <KeyValuePair label="Response due date" value={formatDate(documentData?.demandDetails?.demandResponseDueDate) || "Not on File"} />
                    </Stack>

                </DetailCard>
            </Grid>

            {/* case team */}
            <Grid
                item
                sx={gridItemStyles}
            >
                <DetailCard
                    icon={<GroupOutlined color="action" />}
                    label="Case team"
                >
                    <Stack
                        spacing={1}
                    >
                        <KeyValuePair label="Primary contact" value={(documentData?.sendingFirm?.primaryContact?.firstName || "Not on File") + " " + (documentData?.sendingFirm?.primaryContact?.lastName ?? "")} />
                        <KeyValuePair label="Approving attorney" value={documentData?.sendingFirm.attorney?.firstName || "Not on File"} value2={documentData?.sendingFirm.attorney?.lastName ?? ""} />
                        <Stack>
                            {collaboratorsValues.map((collaborator, index) => {
                                return (
                                    index < 2 ? <KeyValuePair useKeyOptionalStyles={index === 0} key={index} label={index === 0 ? "Case collaborators" : ""} value={collaborator} /> :
                                        index === 2 ?
                                            <Tooltip title={collaboratorsValues.map((collaborator, index) => {
                                                return (
                                                    index >= 2 ?
                                                        <Typography key={index} variant="body2">{collaborator}</Typography>
                                                        :
                                                        null
                                                )
                                            })} arrow placement="left">
                                                <Typography variant="body2">{`+ ${collaboratorsList.length - 2} more`}</Typography>
                                            </Tooltip>
                                            :
                                            null
                                )
                            })}
                        </Stack>
                    </Stack>
                </DetailCard>
            </Grid>

            {/* demand scorecard */}
            <DemandScoreCard />


        </Grid>
    )
}

export default DetailsAndInsights