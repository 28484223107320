export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_mLtycnzqE",
    "USER_POOL_APP_CLIENT_ID": "24rarmg0gnre49ksubf1rjli55",
    "ROOT_API_URL": "https://hdk0yjwhu9.execute-api.us-east-1.amazonaws.com/dennist-dev-exchange-documents-api-prod",
    "MFA_ISSUER": "Precedent-dennist-dev-exchange",
    "S3_DEPLOYMENT_BUCKET": "dennist-dev-exchange-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:a801c6c8-4418-4832-ad1c-aabb270a0b5a",
    "RUM_APP_MONITOR_ID": "7ce8e885-e11c-425b-96c9-ffff9087e85c",
    "ENV_STAGE": "dev-dennist"
}
