import { createAsyncThunk } from '@reduxjs/toolkit';
import awsExports from '../../aws-exports';
import { postDocumentFilesApi, pushDataToS3, getUploadUrl } from '../../api';
import { apiSlice } from '../../services/apiSlice';
import { pollPdfRegenerationStatus } from '../../services/pdf/thunks';
import { setPdfUrlPollingLoadingSkeleton } from '../../services/pdf/customSlice';
import { setIsDocumentsUploading } from '../slices/demandDomSlice';
import { setPendingFileDownloadCount } from '../slices/documentSlice';

const rootApiUrl = awsExports.ROOT_API_URL;

export const fetchInventoryDemandData = createAsyncThunk(
    'Inventory/fetchInventoryDemandDataStatus',
    async ({ requestBody, token }, { }) => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: token
            },
            body: JSON.stringify(requestBody)
        };

        const response = await fetch(rootApiUrl + "/inventory", settings)
            .then((response) => response.json())

        return { response, isActive: requestBody.active_filter };
    },
);

export const uploadFiles = createAsyncThunk(
  'DemandDom/uploadFiles',
  async ({ documentId, user, sendForAiProcessing, shouldFetchDocumentData = true }, { dispatch, getState }) => {
    // loading state
    dispatch(setIsDocumentsUploading(true));
    dispatch(setPdfUrlPollingLoadingSkeleton({ [documentId]: true }));

    const state = getState();
    const { documentUploaderList } = state.DemandDom;
    const externalFiles = documentUploaderList.filter((file) => Boolean(file.isExternalFile));

    // get upload urls and upload files to S3
    const uploadPromises = documentUploaderList.map(async (file) => {
      try {

        // Only upload files that are from the user's local system
        let success = false;
        if (!file.isExternalFile) {
          const uploadFields = await getUploadUrl(file.name, documentId, user);
          success = await pushDataToS3(uploadFields, file);
        }
        else {
          console.log(`${file.name}: is an external file. Not uploading to S3.`)
          success = true;
        }

        return {
          success: success,
          file: file,
        };

      } catch (error) {
        console.log(`${file.name}: Error with uploading file: ${error}`);
      }
    });

    const successfulFiles = [];
    const failedFiles = [];

    const promiseResults = await Promise.all(uploadPromises);

    // post uploaded files to document files api
    for (const promiseResult of promiseResults) {
      !promiseResult?.file?.error ? successfulFiles.push(promiseResult.file) : failedFiles.push(promiseResult.file);
    }

    if (successfulFiles.length > 0) {
      const uploaderListMappedtoFileBody = successfulFiles.map((file) => {
        return {
          ...file,
          fileName: file.name,
          detectedType: file.type,
          enableAiProcessing: sendForAiProcessing,
        };
      });

      dispatch(setPendingFileDownloadCount(externalFiles.length));

      try {
        await postDocumentFilesApi(documentId, { files: uploaderListMappedtoFileBody }, user);
      } catch (error) {
        console.log('error from postDocumentFilesApi', error);
      }
    }

    // reset loading state. PDF regen with optional refetch of document data
    dispatch(setIsDocumentsUploading(false));
    shouldFetchDocumentData
      ? dispatch(apiSlice.endpoints.getDocumentData?.initiate({ documentId, pollPdfStatus: true }, { forceRefetch: true }))
      : dispatch(pollPdfRegenerationStatus({ documentId }));

    return {
      successful: successfulFiles,
      failed: failedFiles,
    };
  }
);
