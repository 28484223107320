import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { Autocomplete, Stack, Snackbar, Alert, Button, IconButton, Typography, InputAdornment, TextField, Switch, FormControlLabel, Card, CardContent, Divider, Grid, Box, Paper } from '@mui/material';
import awsExports from '../aws-exports';
import { isValidEmail, MAX_DAYS_RELATIVE_DUE_DATE } from '../common';
import { LAW_FIRM_ADMIN_ROLE } from '../common-roles';
import { AlternateEmail, EditOutlined, SaveRounded, CloseRounded, DeleteRounded, ContactMailOutlined, DeleteOutline } from '@mui/icons-material';
import StatusDialog from '../dialogs/StatusDialog';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import { useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import { getUsersApi } from '../api';
import { isLawFirmAdmin } from '../common-roles';
import { setAllCustomerUsers } from '../redux/slices/adminEditTemplateSlice';
import { useDispatch } from 'react-redux';
import { element } from 'prop-types';

const MAX_MINUTES_REMINDER = 30 * 24 * 60;

const CustomerConfigView = ({ updateCallback, user }) => {
    const dispatch = useDispatch();
    const AdminEditTemplateState = useSelector((state) => state.AdminEditTemplate);
    const selectedCustomer = AdminEditTemplateState.customerData;

    const customerTypes = [
        {
            value: "lawfirm",
            label: "Law Firm"
        }
    ];

    const newApproverRoles = [
        {
            value: LAW_FIRM_ADMIN_ROLE,
            label: "Law Firm Admin"
        }
    ];

    const navigate = useNavigate();
    const isNewCustomer = selectedCustomer["isNew"];

    const rootApiUrl = awsExports.ROOT_API_URL;
    const [editMode, setEditMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [toastOpen, setToastOpen] = useState(false);
    const [toastMessage, setToastMessage] = useState();
    const [toastSeverity, setToastSeverity] = useState();
    const [statusDialogOpen, setStatusDialogOpen] = useState(false);
    const [statusCloseDisabled, setStatusCloseDisabled] = useState(false);
    const [statusDialogText, setStatusDialogText] = useState("");
    const [statusDialogTitle, setStatusDialogTitle] = useState("");
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [confirmationDialogText, setConfirmationDialogText] = useState("");
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState("");
    const [saveConfirmationDialogOpen, setSaveConfirmationDialogOpen] = useState(false);
    const [saveConfirmationDialogText, setSaveConfirmationDialogText] = useState("");
    const [saveConfirmationDialogTitle, setSaveConfirmationDialogTitle] = useState("");
    const [precedentUsers, setPrecedentUsers] = useState([]);
    const [adminUsers, setAdminUsers] = useState([]);
    const [allUsers, setAllUsers] = useState([]);

    // Customer form state
    const defaultFormData = {};
    const [formData, setFormData] = useState(defaultFormData);
    const [orignalFormData, setOrignalFormData] = useState(defaultFormData);
    const [formError, setFormError] = useState({});
    const [formDirty, setFormDirty] = useState(false);
    const [formValid, setFormValid] = useState(true);

    const saveCustomerApi = async (carrier) => {

        setSaving(true);

        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify(carrier)
        };

        const response = await fetch(
            rootApiUrl + "/customer", settings
        ).then((response) => response)
            .finally(() => {
                setSaving(false);
            });

        if (response.status == 200) {
            setEditMode(false);
            showToast(`${selectedCustomer.name} saved successfully`, "success");
            updateCallback(selectedCustomer.customerId);
        }
        else {
            showStatusDialog("Error Saving Customer", "There was an error saving the customer data.");
        }
    };

    const getCustomerAdminUsersApi = async () => {
        if (selectedCustomer?.customerId) {

            const response = await getUsersApi(user, selectedCustomer?.customerId);
            if (response.status == 200) {
                const usersJson = await response.json();

                const adminUsersList = []
                usersJson.forEach(element => {
                    if (isLawFirmAdmin(element)) {
                        adminUsersList.push({ value: element.userId, label: element.emailAddress, emailAddress: element.emailAddress, userId: element.userId });
                    }
                });
                setAdminUsers([...adminUsersList]);
                setAllUsers(usersJson);
                dispatch(setAllCustomerUsers(usersJson));
            }
            else {
                console.log("Could not load admin users: " + response);
            }
        }
    };

    const getPrecedentUsersApi = async () => {
        const settings = {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: user.signInUserSession.accessToken.jwtToken
            },
            body: JSON.stringify({ customerId: "precedent" })
        };

        const response = await fetch(
            rootApiUrl + "/users", settings
        ).then((response) => response.json())
            .finally(() => {
                setLoading(false);
            });

        const precedentUsersList = []
        response.forEach(element => {
            const name = buildFullName(element);
            precedentUsersList.push({ value: element.userId, label: name, emailAddress: element.emailAddress, userId: element.userId });
        });

        setPrecedentUsers([...precedentUsersList]);
    };

    const mapCustomerTypeToLabel = (customerType) => {
        if (customerType == "lawfirm")
            return "Law Firm";
        else
            return customerType;
    };

    const buildFullName = (user) => {
        return user.firstName + " " + user.lastName
    };

    const convertMinutesToHours = (minutes) => {
        return minutes / 60;
    };

    const findUser = (emailOrId, userList) => userList.find(({ value, userId, emailAddress }) => [value, userId, emailAddress].includes(emailOrId));


    const getdemandResponseRelativeDueDateLabelText = (relativeDueDate) => {
        return relativeDueDate == 0 ? "Not Configured" : `${relativeDueDate} days`;
    };

    const isEmailDomainInUse = (domain) => {
        const matched = allUsers.find(element => element.emailAddress.endsWith(`@${domain}`));
        return matched;
    };

    const isFirmNameValid = (firmName) => {
        const emailRegex = /^[ A-Za-z0-9~!$%^&*_=+}{'?\-.]*$/;
        return emailRegex.test(firmName);
    };


    /*
     * Form
     */

    const mapServerDataToForm = () => {
        const localFormData = {};

        localFormData["name"] = selectedCustomer.name;
        localFormData["customerType"] = { value: selectedCustomer.customerType, label: mapCustomerTypeToLabel(selectedCustomer.customerType) };
        localFormData["customerShortLabel"] = selectedCustomer.customerShortLabel;
        localFormData["tin"] = selectedCustomer.customerEntityData?.tin;
        localFormData["customMessage"] = selectedCustomer.customerEntityData?.deliveryNotificationCustomMessage;
        localFormData["createDemandEnabled"] = selectedCustomer.createDemandEnabled;
        localFormData["canComposeGlobalDemand"] = selectedCustomer.canComposeGlobalDemand;
        localFormData["viewAllEnabled"] = selectedCustomer.viewAllEnabled;
        localFormData["defaultRelativeDueDate"] = selectedCustomer.defaultRelativeDueDate;
        localFormData["useDemandNotesEnabled"] = selectedCustomer.useDemandNotesEnabled;
        localFormData["useDividerPagesEnabled"] = selectedCustomer.useDividerPagesEnabled;
        localFormData["canEditBeforeAiProcessing"] = selectedCustomer.canEditBeforeAiProcessing;
        localFormData["displayMriFindings"] = selectedCustomer?.displayMriFindings;
        localFormData["litifyUrl"] = selectedCustomer.customerEntityData?.litifyUrl;

        if (isNewCustomer) {
            localFormData["defaultApproverRole"] = newApproverRoles[0];
        }

        if (selectedCustomer.ownerId) {
            const user = findUser(selectedCustomer.ownerId, precedentUsers);
            localFormData["owner"] = user;
        }

        if (selectedCustomer.defaultFirmApprovalEmail && adminUsers) {
            const user = findUser(selectedCustomer.defaultFirmApprovalEmail, adminUsers);
            localFormData["defaultApprover"] = user;
        }

        if (selectedCustomer.validEmailDomains) {
            localFormData["validEmailDomains"] = [...selectedCustomer.validEmailDomains];
        }
        else {
            localFormData["validEmailDomains"] = [""];
        }

        localFormData["approvalCCs"] = [];
        if (selectedCustomer.firmApprovalCC) {
            selectedCustomer.firmApprovalCC.map(
                (email) => localFormData["approvalCCs"].push(findUser(email, adminUsers))
            );
        }

        localFormData["defaultCollaborators"] = [];
        if (selectedCustomer.defaultCollaboratorsUserIds) {
            selectedCustomer.defaultCollaboratorsUserIds.map(
                (id) => localFormData["defaultCollaborators"].push(findUser(id, allUsers))
            );
        }

        if (selectedCustomer.firmApprovalRemindersConfig && selectedCustomer.firmApprovalRemindersConfig.length > 0) {
            const firstReminder = findReminderConfig("first_reminder", selectedCustomer);
            if (firstReminder) {
                localFormData["firstReminderTime"] = firstReminder["minutesFromStart"];
            }

            const secondReminder = findReminderConfig("second_reminder", selectedCustomer);
            if (secondReminder) {
                localFormData["secondReminderTime"] = secondReminder["minutesFromStart"];
            }

            const thirdReminder = findReminderConfig("third_reminder", selectedCustomer);
            if (thirdReminder) {
                localFormData["thirdReminderTime"] = thirdReminder["minutesFromStart"];
            }
        }

        setOrignalFormData({ ...localFormData });
        setFormData({ ...localFormData });

    };

    const mapFormDataToServer = () => {
        const serverData = cloneDeep(selectedCustomer);

        serverData["name"] = formData["name"];
        serverData["customerType"] = isNewCustomer ? getFormData("customerType").value : serverData.customerType;
        serverData["customerShortLabel"] = formData["customerShortLabel"] || "";
        serverData["customerEntityData"]["tin"] = formData["tin"];
        serverData["customerEntityData"]["deliveryNotificationCustomMessage"] = formData["customMessage"];
        serverData["createDemandEnabled"] = formData["createDemandEnabled"];
        serverData["canComposeGlobalDemand"] = formData["canComposeGlobalDemand"];
        serverData["viewAllEnabled"] = formData["viewAllEnabled"];
        serverData["defaultRelativeDueDate"] = formData["defaultRelativeDueDate"] ? parseInt(formData["defaultRelativeDueDate"]) : 0;
        serverData["useDemandNotesEnabled"] = formData["useDemandNotesEnabled"];
        serverData["useDividerPagesEnabled"] = formData["useDividerPagesEnabled"];
        serverData["canEditBeforeAiProcessing"] = formData["canEditBeforeAiProcessing"];
        serverData["customerEntityData"]["displayMriFindings"] = formData["displayMriFindings"];
        serverData["customerEntityData"]["litifyUrl"] = formData["litifyUrl"];
        serverData["isNewCustomer"] = isNewCustomer;
        if (formData["owner"]) {
            serverData["ownerId"] = formData["owner"].value;
        }

        if (isNewCustomer) {
            serverData["defaultFirmApprovalRole"] = formData["defaultApproverRole"].value;
            serverData["defaultFirmApprovalFirstName"] = formData["defaultApproverFirstName"]
            serverData["defaultFirmApprovalLastName"] = formData["defaultApproverLastName"]
            serverData["defaultFirmApprovalEmail"] = formData["defaultApproverEmail"];
        }
        else {
            if (formData["defaultApprover"]) {
                serverData["defaultFirmApprovalEmail"] = formData["defaultApprover"].emailAddress;
            } else {
                serverData["defaultFirmApprovalEmail"] = "";
            }
        }

        if (formData["validEmailDomains"]) {
            serverData["validEmailDomains"] = [...formData["validEmailDomains"]];
        }

        if (formData["approvalCCs"].length > 0) {
            serverData["firmApprovalCC"] = [];
            formData["approvalCCs"].map((user) => serverData["firmApprovalCC"].push(user.emailAddress));
        } else {
            serverData["firmApprovalCC"] = [];
        }

        serverData["defaultCollaboratorsUserIds"] = [...formData["defaultCollaborators"].map((user) => user.userId)];

        serverData["firmApprovalRemindersConfig"] = serverData.firmApprovalRemindersConfig;
        if (formData["firstReminderTime"]) {
            const firstReminder = cloneDeep(findReminderConfig("first_reminder", serverData));
            if (firstReminder) {
                firstReminder["minutesFromStart"] = parseInt(formData["firstReminderTime"]);
                serverData["firmApprovalRemindersConfig"][0] = firstReminder;
            }

        }

        if (formData["secondReminderTime"]) {
            const reminder = cloneDeep(findReminderConfig("second_reminder", serverData));
            if (reminder) {
                reminder["minutesFromStart"] = parseInt(formData["secondReminderTime"]);
                serverData["firmApprovalRemindersConfig"][1] = reminder;
            }

        }

        if (formData["thirdReminderTime"]) {
            const reminder = cloneDeep(findReminderConfig("third_reminder", serverData));
            if (reminder) {
                reminder["minutesFromStart"] = parseInt(formData["thirdReminderTime"]);
                serverData["firmApprovalRemindersConfig"][2] = reminder;
            }

        }

        return serverData;
    };

    const getFormData = (id) => {
        if (id in formData) {
            return formData[id];
        }
        return "";
    };

    const resetFormData = () => {
        setFormDirty(false);
        setFormError({});
        setFormData({ ...defaultFormData });
        setOrignalFormData({ ...defaultFormData });
    };


    const handleAutocompleteElementChange = (elementId, newValue) => {
        if (typeof newValue === 'string') {
        } else if (newValue && newValue.value) {
            formData[elementId] = newValue;
        } else {
            formData[elementId] = "";
        }

        setFormData({ ...formData });
    };

    const handleFormElementChange = (event, id) => {
        const elementId = id || event.target.id;
        if (event.target.type == 'checkbox') {
            formData[elementId] = event.target.checked
        }
        else {
            formData[elementId] = event.target.value;
        }

        setFormDirty(false);
        for (var key in formData) {
            if (formData[key] != "") {
                setFormDirty(true);
            }
        }
        setFormData({ ...formData });

        /**
         * Do general form validation
         */

        validateForm(elementId);
    };

    const validateForm = (elementId) => {

        var localFormError = {};

        if (elementId == "name") {
            if (!isFirmNameValid(formData[elementId])) {
                localFormError[elementId] = "Invalid customer name. Only alphanumeric and ~!$%^&*_=+}{'?-. are accepted";
            }

            if (!formData[elementId] || formData[elementId].length == 0) {
                localFormError[elementId] = "Firm name is required";
            }

        }

        if (isNewCustomer) {
            if (!formData["defaultApproverFirstName"] || formData["defaultApproverFirstName"].length == 0) {
                localFormError["defaultApproverFirstName"] = "Approver first name is required";
            }

            if (!formData["defaultApproverLastName"] || formData["defaultApproverLastName"].length == 0) {
                localFormError["defaultApproverLastName"] = "Approver last name is required";
            }

            if (!formData["defaultApproverEmail"] || formData["defaultApproverEmail"].length == 0) {
                localFormError["defaultApproverEmail"] = "Approver email is required";
            }
        }

        /*
         * Make sure the approver email is one of the valid doamins
         */
        if (getFormData("defaultApproverEmail")) {

            var validEmail = false;
            getFormData("validEmailDomains").forEach((domain) => {
                if (getFormData("defaultApproverEmail").toLowerCase().endsWith(domain.toLowerCase())) {
                    validEmail = true;
                }
            });

            if (!validEmail) {
                localFormError["defaultApproverEmail"] = "The email address must belong to one of the valid domains.";
            }
            else if (!isValidEmail(formData["defaultApproverEmail"])) {
                localFormError["defaultApproverEmail"] = "Invalid email address.";
            }

        }

        if (!formData["validEmailDomains"] || (formData["validEmailDomains"].length === 1 && formData["validEmailDomains"][0] === "")) {
            localFormError["domain0"] = "At least one email domain is required.";
        }

        setFormValid(Object.keys(localFormError).filter((key) => localFormError[key].length > 0).length === 0);
        setFormError({ ...localFormError });
    }

    /*
     * Handlers
     */

    const findReminderConfig = (reminderName, customerObject) => {

        if (customerObject.firmApprovalRemindersConfig) {
            return customerObject.firmApprovalRemindersConfig.find((element) => element.reminder_name == reminderName);
        }

        return null;
    };

    const handleAddApprovalCCClick = (e) => {
        formData["approvalCCs"].push("");
        setFormData({ ...formData });
        setFormDirty(true);
    };

    const handleApprovalCCDeleteClick = (index) => {
        formData["approvalCCs"].splice(index, 1);
        setFormData({ ...formData });
        setFormDirty(true);
    };

    const handleAddDefaultCollaboratorClick = (e) => {
        formData["defaultCollaborators"].push("");
        setFormData({ ...formData });
        setFormDirty(true);
    };

    const handleCollaboratorDeleteClick = (index) => {
        formData["defaultCollaborators"].splice(index, 1);
        setFormData({ ...formData });
        setFormDirty(true);
    };

    const handleApprovalCCChange = (event, newValue, index) => {
        if (newValue && newValue.value) {
            formData["approvalCCs"][index] = newValue;
        }
        setFormData({ ...formData });
    };

    const handleCollaboratorChange = (event, newValue, index) => {
        if (newValue?.userId) {
            formData["defaultCollaborators"][index] = newValue
        }
        setFormData({ ...formData });
    };

    const getApprovalCCValue = (index) => {
        if (formData["approvalCCs"].length > index) {
            return formData["approvalCCs"][index];
        }
        return null;
    };

    const getCollaboratorValue = (index) => {
        if (formData["defaultCollaborators"].length > index) {
            return formData["defaultCollaborators"][index]
        }
        return null;
    };

    const handleAddDomainClick = (e) => {
        formData["validEmailDomains"].push("");
        setFormData({ ...formData });
    };

    const handleDomainDeleteClick = (index) => {
        formData["validEmailDomains"].splice(index, 1);
        setFormData({ ...formData });
    };

    const handleDomainChange = (event, index) => {
        formData["validEmailDomains"][index] = event.target.value;
        setFormData({ ...formData });
        validateForm("validEmailDomains");
    };

    const handleEditClick = (e) => {
        setEditMode(true);
    };

    const handleCancelEditClick = (e) => {
        if (JSON.stringify(orignalFormData) !== JSON.stringify(formData)) {
            setConfirmationDialogTitle("Discard changes?");
            setConfirmationDialogText("Are you sure you want to discard your work? Unsaved changes will be lost.");
            setConfirmationDialogOpen(true);
        }
        else {
            handleCancelFormOk();
        }
    };

    const handleCancelFormOk = () => {
        setConfirmationDialogOpen(false);

        if (!isNewCustomer) {
            mapServerDataToForm();
            setEditMode(false);
        }
        else {
            navigate('/customer-management');
        }
    };

    const handleCancelFormCancel = () => {
        setConfirmationDialogOpen(false);
    };

    const handleStatusDialogClose = () => {
        setStatusDialogOpen(false);
    };

    const showStatusDialog = (title, text) => {
        setStatusDialogTitle(title);
        setStatusDialogText(text);
        setStatusDialogOpen(true);
    };

    const handleToastClose = () => {
        setToastMessage("");
        setToastOpen(false);
    };

    const showToast = (message, severity) => {
        setToastSeverity(severity);
        setToastMessage(message);
        setToastOpen(true);
    };

    const handleSaveCarrierConfirmOk = () => {
        const carrier = mapFormDataToServer();
        saveCustomerApi(carrier);
        setSaveConfirmationDialogOpen(false);
    };

    const handleSaveCarrierConfirmCancel = () => {
        setSaveConfirmationDialogOpen(false);
    };

    const handleSaveCustomerClick = (e) => {
        setSaveConfirmationDialogTitle("Apply changes?");
        setSaveConfirmationDialogText(
            <Stack
                spacing={2}>
                <Typography>
                    Changes may impact the way Exchange delivers demands.
                </Typography>
                <Typography>Are you sure you want to continue?</Typography>
            </Stack>
        );
        setSaveConfirmationDialogOpen(true);
    };

    const EditModeButtons = () => {

        return (
            !editMode ?
                <IconButton onClick={handleEditClick}>
                    <EditOutlined />
                </IconButton>
                :
                <Stack direction={"row"}>
                    <IconButton onClick={handleCancelEditClick}>
                        <CloseRounded />
                    </IconButton>

                    <IconButton disabled={!formValid && !saving} onClick={(e) => { handleSaveCustomerClick(e) }}>
                        <SaveRounded />
                    </IconButton>
                </Stack>

        )
    }

    const CardHeader = ({ title }) => {
        return (
            <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: "divider",
                    px: 2,
                    py: 1
                }}
            >
                <Typography
                    variant='h6'
                    color={"primary"}
                >
                    {title}
                </Typography>
                <EditModeButtons />
            </Stack>
        )
    }

    /* 
     * hooks
     */
    useEffect(() => {
        mapServerDataToForm();
    }, [precedentUsers, adminUsers]);

    useEffect(() => {
        user && mapServerDataToForm();
    }, [selectedCustomer, user]);

    useEffect(() => {
        getPrecedentUsersApi();
        if (selectedCustomer?.customerId) {
            getCustomerAdminUsersApi();
        }
        else if (isNewCustomer) {
            setEditMode(true);
            setFormValid(false);
        }
    }, [selectedCustomer?.customerId]);

    return (
        <div>
            <Stack spacing={2}>
                <Stack
                    spacing={2}
                    direction={"row"}
                    alignItems={"stretch"}
                    sx={{
                        width: '100%'
                    }}
                >
                    {/* customer details */}
                    <Card
                        sx={{
                            p: 0,
                            width: '100%',
                        }}
                    >
                        <CardHeader title="Customer details" />

                        <CardContent
                            sx={{ maxHeight: 500, overflowY: "auto", p: 2 }}
                        >
                            {!editMode ?
                                <Stack spacing={2}>
                                    <Stack direction={"row"} spacing={2}>
                                        <Stack style={{ width: "50%" }} spacing={1}>
                                            <Typography variant="inputLabel" color={"text.secondary"}>Name</Typography>
                                            <Typography variant="body1" >{getFormData("name")}</Typography>
                                        </Stack>

                                        <Stack style={{ width: "50%" }} spacing={1}>
                                            <Typography variant="inputLabel" color={"text.secondary"}>Type</Typography>
                                            <Typography variant="body1" >{mapCustomerTypeToLabel(selectedCustomer.customerType)}</Typography>
                                        </Stack>
                                    </Stack>

                                    <Stack direction={"row"} spacing={2}>
                                        <Stack style={{ width: "50%" }} spacing={1}>
                                            <Typography variant="inputLabel" color={"text.secondary"}>Account owner</Typography>
                                            <Typography variant="body1" >{getFormData("owner") ? getFormData("owner").label : "No owner configured"}</Typography>
                                        </Stack>

                                        <Stack style={{ width: "50%" }} spacing={1}>
                                            <Typography variant="inputLabel" color={"text.secondary"}>TIN</Typography>
                                            <Typography variant="body1" >{getFormData("tin")}</Typography>
                                        </Stack>
                                    </Stack>
                                    <Stack direction={"row"} spacing={2}>
                                        <Stack style={{ width: "50%" }} spacing={1}>
                                            <Typography variant="inputLabel" color={"text.secondary"}>Customer short label</Typography>
                                            <Typography variant="body1" >{getFormData("customerShortLabel") ? getFormData("customerShortLabel") : "Not configured"}</Typography>
                                        </Stack>
                                    </Stack>

                                    {/* case data */}
                                    <Stack spacing={1}>
                                        <Typography variant="inputLabel" color={"text.secondary"}>Case data</Typography>

                                        {!editMode && getFormData("litifyUrl") &&
                                            <Typography variant="body1" color="primary">{getFormData("litifyUrl")}</Typography>
                                        }

                                        {!editMode && !getFormData("litifyUrl") &&
                                            <Typography variant="body1" color="primary">Not configured for this customer</Typography>
                                        }
                                    </Stack>
                                </Stack>
                                :
                                <>

                                    <Grid
                                        container
                                        rowSpacing={1}
                                        columnSpacing={2}
                                    >
                                        <Grid xs={6} item>
                                            <TextField label='Name' required={true} fullWidth value={getFormData('name')}
                                                error={formError["name"]} helperText={formError["name"] || ''}
                                                onChange={(e) => { handleFormElementChange(e, 'name') }} />
                                        </Grid>
                                        <Grid xs={6} item>
                                            {!isNewCustomer &&
                                                <Typography variant="inputLabel" color={"text.secondary"}>Type</Typography>
                                            }

                                            {isNewCustomer &&
                                                <Autocomplete
                                                    id="customerType"
                                                    value={getFormData('customerType')}
                                                    onChange={(event, newValue) => {
                                                        handleAutocompleteElementChange('customerType', newValue);
                                                    }}
                                                    forcePopupIcon
                                                    autoSelect
                                                    handleHomeEndKeys
                                                    options={customerTypes}
                                                    fullWidth={true}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Regular option
                                                        return option.label;
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField  {...params} fullWidth label="Customer Type" />
                                                    )}
                                                />
                                            }

                                            {!isNewCustomer &&
                                                <Typography variant="body1">{mapCustomerTypeToLabel(selectedCustomer.customerType)}</Typography>
                                            }
                                        </Grid>


                                        <Grid xs={6} item>

                                            <Autocomplete
                                                value={getFormData('owner')}
                                                onChange={(event, newValue) => {
                                                    handleAutocompleteElementChange('owner', newValue);
                                                }}
                                                selectOnFocus
                                                forcePopupIcon
                                                autoSelect
                                                handleHomeEndKeys
                                                id="owner"
                                                options={precedentUsers}
                                                fullWidth={true}
                                                getOptionLabel={(option) => {
                                                    // Value selected with enter, right from the input
                                                    if (typeof option === 'string') {
                                                        return option;
                                                    }
                                                    // Regular option
                                                    return option.label;
                                                }}
                                                getOptionKey={(option) => option.userId}
                                                freeSolo
                                                renderInput={(params) => (
                                                    <TextField  {...params} fullWidth label="Owner" />
                                                )}
                                            />
                                        </Grid>
                                        <Grid xs={6} item>


                                            <TextField id="tin" label='TIN' fullWidth value={getFormData('tin')} onChange={(e) => { handleFormElementChange(e, 'tin') }} />
                                        </Grid>

                                        <Grid xs={6} item>

                                            <TextField label='Customer short label' fullWidth value={getFormData('customerShortLabel')} onChange={(e) => { handleFormElementChange(e, 'customerShortLabel') }} />
                                        </Grid>

                                        <Grid xs={12} item>
                                            {editMode &&
                                                <TextField fullWidth label="Case management URL"
                                                    onChange={(e) => { handleFormElementChange(e, 'litifyUrl') }} value={getFormData("litifyUrl")}></TextField>
                                            }
                                        </Grid>

                                    </Grid>
                                </>
                            }
                        </CardContent>

                    </Card>

                    {/* valid email domains */}
                    <Card
                        sx={{
                            p: 0,
                            width: '100%',
                        }}
                    >
                        <CardHeader title="Valid email domains" />

                        <CardContent
                            sx={{ maxHeight: 500, overflowY: "auto", p: 2 }}
                        >
                            <Stack spacing={2}>
                                {getFormData("validEmailDomains") &&
                                    getFormData("validEmailDomains").map((domain, index) => {
                                        return (
                                            <Stack spacing={1} key={index}>
                                                {!editMode &&
                                                    <>
                                                        <Typography variant="inputLabel" color={"text.secondary"}>Domain name</Typography>
                                                        <Stack direction={"row"} spacing={2}>
                                                            <AlternateEmail color="primaryActions" />
                                                            <Typography variant="body1" color="primary">{domain}</Typography>
                                                        </Stack>
                                                    </>
                                                }

                                                {editMode &&
                                                    <>
                                                        <Stack direction={"row"} spacing={2}>
                                                            <TextField id={"domain" + index} label="Domain Name" variant='outlined' fullWidth value={domain}
                                                                onChange={(e) => { handleDomainChange(e, index) }}
                                                                error={formError["domain" + index]} helperText={formError["domain" + index] || ''}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <AlternateEmail />
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                                disabled={isEmailDomainInUse(domain)}
                                                            />

                                                            <IconButton disabled={isEmailDomainInUse(domain) || getFormData("validEmailDomains").length == 1} onClick={(e) => { handleDomainDeleteClick(index) }}><DeleteOutline /></IconButton>
                                                        </Stack>

                                                        {isEmailDomainInUse(domain) &&
                                                            <Typography variant="body2" color="primary">{domain} cannot be edited or deleted because it's in use.</Typography>
                                                        }

                                                    </>
                                                }

                                            </Stack>
                                        )
                                    })
                                }

                                {!editMode && (!getFormData("validEmailDomains") || getFormData("validEmailDomains").length == 0) &&
                                    <Typography variant="body1" color="primary">There are not any domains configured.</Typography>
                                }

                                {editMode &&
                                    <Button style={{ width: "fit-content" }} variant='text'
                                        onClick={handleAddDomainClick}
                                        startIcon={<AlternateEmail />} color='primary' >Add Domain</Button>
                                }
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>


                <Stack
                    spacing={2}
                    alignItems={"stretch"}
                    direction="row"
                >
                    {/* demand emails */}
                    <Card
                        sx={{
                            p: 0,
                            width: '100%'
                        }}
                    >
                        <CardHeader title="Demaind emails" />

                        <CardContent
                            sx={{ maxHeight: 500, overflowY: "auto", p: 2 }}
                        >
                            <Stack spacing={2}>
                                {/* default approval emails */}
                                <Stack spacing={1}>
                                    <Typography variant="h7" color="primary">
                                        Default approval email
                                    </Typography>

                                    {!isNewCustomer &&
                                        <Stack spacing={1}>
                                            {!editMode ?
                                                <>
                                                    <Typography variant="inputLabel" color={"text.secondary"}>Email</Typography>
                                                    <Typography variant="body1" color="primary">
                                                        {getFormData("defaultApprover") ? getFormData("defaultApprover").label : "Not Configured"}
                                                    </Typography>
                                                    <Typography variant="body2" color="primary">
                                                        {getFormData("defaultApprover") ? getFormData("defaultApprover").emailAddress : ""}
                                                    </Typography>
                                                </>

                                                :
                                                <Autocomplete
                                                    value={getFormData('defaultApprover')}
                                                    onChange={(event, newValue) => {
                                                        handleAutocompleteElementChange('defaultApprover', newValue);
                                                    }}
                                                    selectOnFocus
                                                    forcePopupIcon
                                                    autoSelect
                                                    handleHomeEndKeys
                                                    id="defaultApprover"
                                                    options={adminUsers}
                                                    fullWidth={true}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Regular option
                                                        return `${option.label} (${option.emailAddress})`;
                                                    }}
                                                    renderOption={({ key, ...props }, option) => <li key={key} {...props}>{option.label}&nbsp;<small>({option.emailAddress})</small></li>}
                                                    renderInput={(params) => (
                                                        <TextField  {...params} fullWidth label="Default Approver" />
                                                    )}
                                                />
                                            }
                                        </Stack>
                                    }
                                    <Divider orientation='horizontal' />

                                    {isNewCustomer && editMode &&
                                        <Stack spacing={1}>

                                            <Alert severity="info" spacing={2} sx={{ mt: 3, mb: 3 }}>
                                                Create a Law Firm Admin to be the default approval email.  This can be edited when more Law Firm Admins exist.
                                            </Alert>

                                            <Stack direction={"row"} spacing={2}>
                                                <Stack style={{ width: "50%" }} spacing={1}>
                                                    <TextField required={true} label='First Name' fullWidth value={getFormData('defaultApproverFirstName')}
                                                        error={formError["defaultApproverFirstName"]} helperText={formError["defaultApproverFirstName"] || ''}
                                                        onChange={(e) => { handleFormElementChange(e, 'defaultApproverFirstName') }} />
                                                </Stack>

                                                <Stack style={{ width: "50%" }} spacing={1}>
                                                    <TextField required={true} label='Last Name' fullWidth value={getFormData('defaultApproverLastName')}
                                                        error={formError["defaultApproverLastName"]} helperText={formError["defaultApproverLastName"] || ''}
                                                        onChange={(e) => { handleFormElementChange(e, 'defaultApproverLastName') }} />
                                                </Stack>
                                            </Stack>

                                            <Stack direction={"row"} spacing={2}>
                                                <Stack style={{ width: "50%" }} spacing={1}>
                                                    <TextField required={true} label='Email' fullWidth value={getFormData('defaultApproverEmail')}
                                                        error={formError["defaultApproverEmail"]} helperText={formError["defaultApproverEmail"] || ''}
                                                        onChange={(e) => { handleFormElementChange(e, 'defaultApproverEmail') }} />
                                                </Stack>

                                                <Stack style={{ width: "50%" }} spacing={1}>
                                                    <Autocomplete
                                                        value={getFormData('defaultApproverRole')}
                                                        disabled={true}
                                                        selectOnFocus
                                                        forcePopupIcon
                                                        autoSelect
                                                        handleHomeEndKeys
                                                        id="defaultApproverRole"
                                                        options={newApproverRoles}
                                                        fullWidth={true}
                                                        getOptionLabel={(option) => {
                                                            // Value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Regular option
                                                            return option.label;
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField  {...params} fullWidth label="Role" />
                                                        )}
                                                    />
                                                </Stack>

                                            </Stack>
                                        </Stack>
                                    }
                                </Stack>

                                {/* approval ccs */}
                                {!isNewCustomer &&
                                    <Stack spacing={1}>

                                        <Typography variant="h7">
                                            Approval CCs
                                        </Typography>

                                        {getFormData("approvalCCs") &&
                                            getFormData("approvalCCs").map((approvalCCUser, index) => {
                                                return (
                                                    <Stack spacing={1}>

                                                        {!editMode &&
                                                            <>
                                                                <Typography variant="inputLabel" color={"text.secondary"}>Email</Typography>
                                                                <Typography variant="body1" color="primary">
                                                                    {approvalCCUser?.label}
                                                                </Typography>
                                                                <Typography variant="body2" color="primary">
                                                                    {approvalCCUser?.emailAddress}
                                                                </Typography>
                                                            </>
                                                        }

                                                        {editMode &&
                                                            <Stack direction={"row"} spacing={2}>
                                                                <Autocomplete
                                                                    id={`approvalccemail${index}`}
                                                                    value={getApprovalCCValue(index)}
                                                                    selectOnFocus
                                                                    forcePopupIcon
                                                                    autoSelect
                                                                    handleHomeEndKeys
                                                                    options={adminUsers}
                                                                    fullWidth={true}
                                                                    onChange={(e, newValue) => { handleApprovalCCChange(e, newValue, index) }}
                                                                    getOptionLabel={(option) => {
                                                                        // Value selected with enter, right from the input
                                                                        if (typeof option === 'string') {
                                                                            return option;
                                                                        }
                                                                        // Regular option
                                                                        return `${option.label} (${option.emailAddress})`;
                                                                    }}
                                                                    renderOption={({ key, ...props }, option) => <li key={key} {...props}>{option.label}&nbsp;<small>({option.emailAddress})</small></li>}
                                                                    renderInput={(params) => (
                                                                        <TextField  {...params} fullWidth label="Approval CC" />
                                                                    )}
                                                                />

                                                                <IconButton onClick={(e) => { handleApprovalCCDeleteClick(index) }}><DeleteRounded /></IconButton>
                                                            </Stack>
                                                        }
                                                    </Stack>
                                                )
                                            })
                                        }

                                        {!editMode && getFormData("approvalCCs") && getFormData("approvalCCs").length == 0 &&
                                            <Typography variant="body1" color="primary">There are not any approval CCs configured.</Typography>
                                        }

                                        {editMode &&
                                            <Button style={{ width: "fit-content" }} variant='text'
                                                onClick={handleAddApprovalCCClick}
                                                startIcon={<ContactMailOutlined />} >Add Approval CC</Button>
                                        }
                                    </Stack>
                                }
                                <Divider orientation='horizontal' />

                                {/* reminder email schedule */}
                                <Stack spacing={1}>
                                    <Typography variant="h7">
                                        Reminder email schedule
                                    </Typography>

                                    {!selectedCustomer.firmApprovalRemindersConfig || selectedCustomer.firmApprovalRemindersConfig.length == 0 &&
                                        <Typography variant="body1" color="primary">No reminders configuration for this customer.</Typography>
                                    }

                                    {selectedCustomer.firmApprovalRemindersConfig && selectedCustomer.firmApprovalRemindersConfig.length > 0 &&
                                        <Stack spacing={2}>
                                            <Stack>
                                                <Stack spacing={0}>
                                                    {!editMode &&
                                                        <>
                                                            <Typography variant="inputLabel" color={"text.secondary"}>Time to first reminder</Typography>
                                                            <Typography variant="body1" color="primary">{getFormData('firstReminderTime')} minutes</Typography>
                                                        </>
                                                    }
                                                    {editMode &&
                                                        <>
                                                            <TextField id="firstReminderTime" type='number' label='Time to first reminder'
                                                                fullWidth value={getFormData('firstReminderTime')} onChange={(e) => { handleFormElementChange(e, 'firstReminderTime') }}
                                                                onChangeCapture={(e) => e.target.value = Math.min(e.target.value, MAX_MINUTES_REMINDER)}
                                                            />
                                                        </>
                                                    }
                                                    <Typography variant="body2" color="primary">default value = {1440}</Typography>
                                                </Stack>
                                            </Stack>

                                            <Stack>
                                                <Stack spacing={0}>
                                                    {!editMode &&
                                                        <>
                                                            <Typography variant="inputLabel" color={"text.secondary"}>Time to second reminder</Typography>
                                                            <Typography variant="body1" color="primary">{getFormData('secondReminderTime')} minutes</Typography>
                                                        </>
                                                    }
                                                    {editMode &&
                                                        <>
                                                            <TextField id="secondReminderTime" type='number' label='Time to second reminder'
                                                                fullWidth value={getFormData('secondReminderTime')} onChange={(e) => { handleFormElementChange(e, 'secondReminderTime') }}
                                                                onChangeCapture={(e) => e.target.value = Math.min(e.target.value, MAX_MINUTES_REMINDER)}
                                                            />
                                                        </>
                                                    }
                                                    <Typography variant="body2" color="primary">default value = {2880}</Typography>
                                                </Stack>
                                            </Stack>

                                            <Stack>
                                                <Stack spacing={0}>
                                                    {!editMode &&
                                                        <>
                                                            <Typography variant="inputLabel" color={"text.secondary"}>Time to third reminder</Typography>
                                                            <Typography variant="body1" color="primary">{getFormData('thirdReminderTime')} minutes</Typography>
                                                        </>
                                                    }

                                                    {editMode &&
                                                        <>
                                                            <TextField id="thirdReminderTime" type='number' label='Time to third reminder'
                                                                fullWidth value={getFormData('thirdReminderTime')} onChange={(e) => { handleFormElementChange(e, 'thirdReminderTime') }}
                                                                onChangeCapture={(e) => e.target.value = Math.min(e.target.value, MAX_MINUTES_REMINDER)}
                                                            />
                                                        </>
                                                    }

                                                    <Typography variant="body2" color="primary">default value = {4320}</Typography>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    }
                                </Stack>

                                <Divider orientation='horizontal' />

                                {/* custom message */}
                                <Stack spacing={1}>
                                    <Typography variant="h7">
                                        Custom message
                                    </Typography>

                                    {!getFormData('customMessage') &&
                                        <Typography variant="body1" color="primary">No custom delivery notification message for this customer.</Typography>
                                    }

                                    {!editMode && getFormData('customMessage') &&
                                        <Stack spacing={2}>
                                            {getFormData('customMessage')}
                                        </Stack>
                                    }

                                    {editMode &&
                                        <Stack spacing={2}>
                                            <TextField multiline={true} rows={5} style={{ width: "100%" }} onChange={(e) => { handleFormElementChange(e, 'customMessage') }} value={getFormData("customMessage")}>
                                            </TextField>
                                        </Stack>
                                    }
                                </Stack>

                                <Divider orientation='horizontal' />
                            </Stack>
                        </CardContent>
                    </Card>

                    {/* demand creation */}
                    <Card
                        sx={{
                            p: 0,
                            width: '100%'
                        }}
                    >
                        <CardHeader title="Demand creation" />

                        <CardContent
                            sx={{ maxHeight: 500, overflowY: "auto", p: 2 }}
                        >
                            <Stack spacing={2}>
                                {/* demand creation */}
                                <Stack spacing={1}>
                                    <FormControlLabel control={
                                        <Switch
                                            disabled={!editMode}
                                            onChange={(e) => { handleFormElementChange(e, 'createDemandEnabled') }}
                                            checked={getFormData('createDemandEnabled')} />}
                                        label="Create demands"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={!editMode}
                                                onChange={(e) => handleFormElementChange(e, 'canComposeGlobalDemand')}
                                                checked={Boolean(getFormData('canComposeGlobalDemand'))}
                                                inputProps={{ 'aria-label': 'Create Global Demands Enabled', maxLength: 50 }}
                                            />
                                        }
                                        label="Create global demands"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={!editMode}
                                                onChange={(e) => { handleFormElementChange(e, 'useDemandNotesEnabled') }}
                                                checked={getFormData('useDemandNotesEnabled')}
                                                inputProps={{ 'aria-label': 'Use Notes Enabled', maxLength: 50 }}
                                            />
                                        }
                                        label="Notes"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={!editMode}
                                                onChange={(e) => { handleFormElementChange(e, 'useDividerPagesEnabled') }}
                                                checked={getFormData('useDividerPagesEnabled')}
                                                inputProps={{ 'aria-label': 'Use Divider Pages' }}
                                            />
                                        }
                                        label="Divider pages"
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={!editMode}
                                                onChange={(e) => { handleFormElementChange(e, 'canEditBeforeAiProcessing') }}
                                                checked={getFormData('canEditBeforeAiProcessing')}
                                                inputProps={{ 'aria-label': 'Can Edit Before AI Processing' }}
                                            />
                                        }
                                        label="Edit demands before AI processing"
                                    />

                                    <Divider orientation='horizontal' />

                                    {/* default collaborators */}
                                    {!isNewCustomer &&
                                        <Stack spacing={1}>

                                            <Typography variant="h7">
                                                Default collaborators
                                            </Typography>

                                            {getFormData("defaultCollaborators") &&
                                                getFormData("defaultCollaborators").map((collaborator, index) => {
                                                    return (
                                                        <Stack spacing={1}>

                                                            {!editMode &&
                                                                <>
                                                                    <Typography variant="body1" color="primary">
                                                                        {collaborator?.name || ""}
                                                                    </Typography>
                                                                    <Typography variant="body2" color="primary">
                                                                        {collaborator?.emailAddress || ""}
                                                                    </Typography>
                                                                </>
                                                            }

                                                            {editMode &&
                                                                <Stack direction={"row"} spacing={2}>
                                                                    <Autocomplete
                                                                        id={`collaborator${index}`}
                                                                        value={getCollaboratorValue(index)}
                                                                        selectOnFocus
                                                                        forcePopupIcon
                                                                        autoSelect
                                                                        handleHomeEndKeys
                                                                        options={allUsers}
                                                                        fullWidth={true}
                                                                        onChange={(e, newValue) => { handleCollaboratorChange(e, newValue, index) }}
                                                                        getOptionLabel={(option) => {
                                                                            // Value selected with enter, right from the input
                                                                            if (typeof option === 'string') {
                                                                                return option;
                                                                            }
                                                                            // Regular option
                                                                            return `${option.emailAddress}`;
                                                                        }}
                                                                        renderOption={({ key, ...props }, option) => (
                                                                            <li key={key} {...props} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                {/* Email on top in larger, darker font */}
                                                                                <Typography variant="body1" fontWeight="bold" color="textPrimary">
                                                                                    {option.emailAddress}
                                                                                </Typography>
                                                                                {/* UserId on bottom in smaller, lighter font */}
                                                                                <Typography variant="body2" color="textSecondary">
                                                                                    {option.userId}
                                                                                </Typography>
                                                                            </li>
                                                                        )}

                                                                        renderInput={(params) => (
                                                                            <TextField  {...params} fullWidth label="Collaborator" />
                                                                        )}
                                                                    />

                                                                    <IconButton onClick={(e) => { handleCollaboratorDeleteClick(index) }}><DeleteRounded /></IconButton>
                                                                </Stack>
                                                            }
                                                        </Stack>
                                                    )
                                                })
                                            }

                                            {!editMode && getFormData("approvalCCs") && getFormData("defaultCollaborators").length == 0 &&
                                                <Typography variant="body1" color="primary">There are not any default collaborators configured.</Typography>
                                            }

                                            {editMode &&
                                                <Button style={{ width: "fit-content" }} variant='text'
                                                    onClick={handleAddDefaultCollaboratorClick}
                                                    startIcon={<ContactMailOutlined />} >Add default collaborator</Button>
                                            }

                                        </Stack>
                                    }
                                </Stack>
                                {/* default relative due date */}
                                <Stack spacing={1}>
                                    <Typography variant="h7">
                                        Default Relative Due Date
                                    </Typography>

                                    {editMode &&
                                        <TextField id="defaultRelativeDueDate" type='number' label='Default Relative Due Date'

                                            fullWidth value={getFormData('defaultRelativeDueDate')} onChange={(e) => { handleFormElementChange(e, 'defaultRelativeDueDate') }}
                                            onChangeCapture={(e) => e.target.value = Math.min(Math.max(parseInt(e.target.value), 0), MAX_DAYS_RELATIVE_DUE_DATE)}
                                        />
                                    }

                                    {!editMode &&
                                        <Typography variant="body1" color="primary">{getdemandResponseRelativeDueDateLabelText(getFormData("defaultRelativeDueDate"))}</Typography>
                                    }
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>

                <Stack
                    direction="row"
                    spacing={2}
                    alignItems={"center"}
                    sx={{ width: '100%' }}
                >
                    {/* mri findings */}
                    <Card
                        sx={{
                            p: 0,
                            width: '100%'
                        }}
                    >
                        <CardHeader title="MRI findings" />

                        <CardContent
                            sx={{ maxHeight: 500, overflowY: "auto", p: 2 }}
                        >
                            <Stack spacing={2}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            disabled={!editMode}
                                            onChange={(e) => { handleFormElementChange(e, 'displayMriFindings') }}
                                            checked={getFormData('displayMriFindings')} />}
                                    label="Display MRI findings"
                                />
                            </Stack>
                        </CardContent>
                    </Card>

                    {/* Inventory settings */}
                    <Card
                        sx={{
                            p: 0,
                            width: '100%'
                        }}
                    >
                        <CardHeader title="Inventory settings" />

                        <CardContent
                            sx={{ maxHeight: 500, overflowY: "auto", p: 2 }}
                        >
                            <Stack spacing={2}>
                                <FormControlLabel
                                    disabled={!editMode}
                                    control={
                                        <Switch onChange={(e) => { handleFormElementChange(e, 'viewAllEnabled') }}
                                            checked={getFormData('viewAllEnabled')} />}
                                    label="Can view all demands" />
                            </Stack>
                        </CardContent>
                    </Card>
                </Stack>

            </Stack>

            <StatusDialog closeDisabled={statusCloseDisabled} handleClose={handleStatusDialogClose} dialogOpen={statusDialogOpen} dialogText={statusDialogText} dialogTitle={statusDialogTitle}></StatusDialog>

            <ConfirmationDialog handleOk={handleCancelFormOk} handleCancel={handleCancelFormCancel} okButtonText="Discard Changes" cancelButtonText="Cancel" dialogOpen={confirmationDialogOpen} dialogText={confirmationDialogText} dialogTitle={confirmationDialogTitle}></ConfirmationDialog>

            <ConfirmationDialog handleOk={handleSaveCarrierConfirmOk} handleCancel={handleSaveCarrierConfirmCancel} okButtonText="Apply changes" cancelButtonText="Cancel" dialogOpen={saveConfirmationDialogOpen} dialogText={saveConfirmationDialogText}></ConfirmationDialog>


            <Snackbar
                open={toastOpen}
                autoHideDuration={4000}
                onClose={handleToastClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
                <Paper elevation={8}>
                    <Alert onClose={handleToastClose} severity="success" sx={{ width: '100%' }}>{toastMessage}</Alert>
                </Paper>
            </Snackbar>

        </div>
    );

}

export default CustomerConfigView;